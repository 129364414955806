import {
  Field,
  Label,
  Option,
  VisuallyHidden,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";

import { DateSetItem } from "src/common/planHelpers/dateHelperTypes";

interface FormWithStartDatePicker {
  startDate: string;
}

interface StartDatePickerProps {
  startDates: DateSetItem[];
}
export const StartDatePickerField: React.FC<StartDatePickerProps> = ({
  startDates,
}) => {
  const { register } = useFormContext<FormWithStartDatePicker>();

  return (
    <Field>
      <VisuallyHidden>
        <Label htmlFor="startDate">Start date</Label>
      </VisuallyHidden>
      <Select
        data-testid="Start date"
        id="startDate"
        {...register("startDate")}
      >
        {startDates.map(({ label, value }) => (
          <Option value={value} key={value}>
            {label}
          </Option>
        ))}
      </Select>
    </Field>
  );
};
