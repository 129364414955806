import {
  MandateResource,
  SchemeIdentifier,
} from "@gocardless/api/dashboard/types";
import {
  Banner,
  BannerLeftAccessoryType,
  BannerStatus,
  BannerVariant,
  FontWeight,
  H4,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { CalendarDate, DateFormatter } from "@internationalized/date";

import { Scheme } from "src/common/scheme";

export enum PaymentType {
  PAYMENT = 1,
  PLAN,
  SUBSCRIPTION,
}

export interface PaymentsRequireApprovalNoticeProps {
  startDate?: CalendarDate;
  mandate: MandateResource;
  schemeIdentifiers: SchemeIdentifier[];
  paymentType: PaymentType;
}

export const PaymentsRequireApprovalNotice: React.FC<
  PaymentsRequireApprovalNoticeProps
> = ({ startDate, mandate, schemeIdentifiers, paymentType }) => {
  const minimumAdvanceNotice = schemeIdentifiers.find((schemeIdentifer) => {
    if (mandate.scheme === Scheme.SepaCore) {
      return schemeIdentifer.scheme === "sepa";
    }
    return schemeIdentifer.scheme === mandate.scheme;
  })?.minimum_advance_notice;

  const formattedStartDate = startDate
    ? new DateFormatter("en-US", { dateStyle: "full" }).format(
        startDate.toDate("Europe/London")
      )
    : "";

  const paymentName =
    paymentType === PaymentType.PAYMENT ? "payment" : "subscription";

  return (
    <Banner
      variant={BannerVariant.Light}
      leftAccessory={{
        type: BannerLeftAccessoryType.Status,
        status: BannerStatus.Info,
      }}
      title={
        <H4 preset={TypePreset.Heading_02} spaceBelow={0.5}>
          {`We will email the customer to approve this ${paymentName}.`}
        </H4>
      }
    >
      To take payment on the{" "}
      <Text weight={FontWeight.Bold}>{formattedStartDate}</Text> we will need
      customer approval at least {minimumAdvanceNotice} working days beforehand,
      otherwise we will take payment {minimumAdvanceNotice} working days after
      the customer approves.
    </Banner>
  );
};
