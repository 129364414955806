import { forwardRef, useEffect, useState } from "react";
import {
  DatePicker,
  FormFieldStatus,
  Hint,
  Field,
} from "@gocardless/flux-react";
import { CalendarDate, DateFormatter } from "@internationalized/date";
import { Trans } from "@lingui/macro";

import { useI18nLocale } from "src/hooks/useI18nLocale";

export interface RestrictedDatePickerProps {
  id?: string;
  ariaLabelledby?: string;
  onChange: (date?: CalendarDate) => void;
  defaultValue: CalendarDate;
  minimumDate: CalendarDate;
  setInvalidDateChosen?: (invalidDateChosen: boolean) => void;
}

export const RestrictedDatePicker = forwardRef<
  HTMLLabelElement,
  RestrictedDatePickerProps
>(
  (
    {
      id,
      ariaLabelledby,
      onChange,
      defaultValue,
      minimumDate,
      setInvalidDateChosen,
      ...field
    },
    ref
  ) => {
    const i18nLocale = useI18nLocale();
    const defaultDate: CalendarDate | undefined =
      defaultValue.year && defaultValue.month && defaultValue.day
        ? (defaultValue as CalendarDate)
        : undefined;

    const [selectedDate, setSelectedDate] = useState<CalendarDate | undefined>(
      defaultDate
    );
    const selectedDateIsTooEarly =
      selectedDate && minimumDate
        ? selectedDate.compare(minimumDate) < 0
        : false;

    const formatDate = (date: CalendarDate): string =>
      new DateFormatter("en-US", { dateStyle: "full" }).format(
        date.toDate("Europe/London")
      );
    const firstAvailableDateFormatted = formatDate(minimumDate);

    useEffect(() => {
      setInvalidDateChosen?.(selectedDateIsTooEarly);
      // TODO: Fix exhaustive dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDateIsTooEarly]);

    return (
      <Field>
        <DatePicker
          ref={ref}
          aria-labelledby={ariaLabelledby}
          format="dd-mm-yyyy"
          id={id}
          i18n={i18nLocale}
          minDate={minimumDate}
          onChange={(date) => {
            if (
              !date ||
              date.day === undefined ||
              date.month === undefined ||
              date.year === undefined
            ) {
              setSelectedDate(undefined);
              onChange(undefined);
            } else {
              const { year, month, day } = date;
              const newCalendarDate = new CalendarDate(year, month, day);
              setSelectedDate(newCalendarDate);
              onChange(newCalendarDate);
            }
          }}
          defaultValue={defaultValue}
          {...field}
        />
        {selectedDateIsTooEarly && (
          <Hint status={FormFieldStatus.Danger}>
            <Trans id="date.picker.selected-too-early">
              Please select a date on or after {firstAvailableDateFormatted}
            </Trans>
          </Hint>
        )}
      </Field>
    );
  }
);
