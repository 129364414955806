import { Box, Space } from "@gocardless/flux-react";
import { useMandateList } from "@gocardless/api/dashboard/mandate";
import { useSchemeIdentifierList } from "@gocardless/api/dashboard/scheme-identifier";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";

import {
  CreateSubscriptionFormComponent,
  MandateList,
} from "./CreateSubscriptionForm";

import { Tip } from "src/components/ui/Tip/Tip";
import { useOrganisation } from "src/libraries/organisation/hooks";
import { useIsRestricted } from "src/common/hooks/useIsRestricted";
import { useVerificationStatus } from "src/libraries/creditor/hooks";
import { VerifyYourAccountError } from "src/components/routes/Customers/CustomerDetail/common/VerifyYourAccountError";
import { PackageSelectionSkippedBanner } from "src/components/routes/SetupPayments/common/components/PackageSelectionSkippedBanner";
import { useHasSelectedPackage } from "src/components/routes/SuccessPlus/api/index.hooks";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export const CreateNewSubscription: React.FC<{
  customerId: string;
}> = ({ customerId }) => {
  const organisation = useOrganisation();
  const { data: mandatesResponse } = useMandateList({
    customer: customerId,
    status: "active,submitted,pending_submission,pending_customer_approval",
  });
  const { data: schemeIdentifierListResponse } = useSchemeIdentifierList({
    organisation: organisation?.id,
  });
  const { i18n } = useLingui();

  const { isRestricted: isSubscriptionCreateRestricted } = useIsRestricted({
    subscriptions: "create",
  });

  const { collectionsPermitted, status } = useVerificationStatus();

  const { isVariationOn: isPackageSelectionEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.PACKAGE_SELECTION_PHASE_ONE,
  });
  const hasSelectedPackage = useHasSelectedPackage();
  const hasSkippedPackageSelection =
    !hasSelectedPackage && isPackageSelectionEnabled;

  const banner = () => {
    if (
      hasSkippedPackageSelection &&
      (status === CreditorsVerificationStatus.InReview ||
        status === CreditorsVerificationStatus.Successful)
    ) {
      return (
        <>
          <Space v={2} />
          <PackageSelectionSkippedBanner
            description={
              <Trans>
                Before you can schedule a subscription payment for this customer
                you’ll need to select a plan and any add-ons.
              </Trans>
            }
          />
        </>
      );
    }

    if (!collectionsPermitted) {
      return (
        <>
          <Space v={2} />
          <VerifyYourAccountError
            header={
              <Trans id="existing-customer-create.verify-banner.header">
                Please verify to create a subscription
              </Trans>
            }
            subtitle={
              <Trans id="existing-customer-create.verify-banner.description">
                Before you can schedule a subscription payment for this customer
                you’ll need to verify your account.
              </Trans>
            }
          />
        </>
      );
    }

    return (
      <Tip
        header={i18n._(
          t({
            id: "existing-customer-create.tip.header",
            message: "Create and add your customer to a new Subscription",
          })
        )}
        details={i18n._(
          t({
            message:
              "Create a unique individual Subscription for this customer and get paid automatically with no hassle. You can also save this Subscription as a template for future by ticking the box at the bottom.",
          })
        )}
        showTipTag
      />
    );
  };

  return (
    <Box>
      {banner()}
      <Space v={1.5} />
      {mandatesResponse?.mandates?.length ? (
        <CreateSubscriptionFormComponent
          mandateList={mandatesResponse?.mandates as MandateList}
          schemeIdentifiers={
            schemeIdentifierListResponse?.scheme_identifiers || []
          }
          isSubscriptionCreateRestricted={
            isSubscriptionCreateRestricted ||
            hasSkippedPackageSelection ||
            !collectionsPermitted
          }
        />
      ) : null}
    </Box>
  );
};
