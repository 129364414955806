import Image from "next/image";
import {
  AlignItems,
  Box,
  ColorPreset,
  H4,
  JustifyContent,
  P,
  TextAlign,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

import SubscriptionForCustomerImage from "src/assets/png/request-payment/subscription-for-customer@2x.png";

export const ExistingCustomerCreateInstructions: React.FC = () => (
  <Box
    layout="flex"
    flexDirection="column"
    alignItems={AlignItems.Center}
    alignSelf={AlignItems.Center}
    justifyContent={JustifyContent.Center}
  >
    <Image src={SubscriptionForCustomerImage} alt="" width={256} height={256} />
    <H4 spaceBelow={1} preset={TypePreset.Heading_04}>
      <Trans>Taking Subscriptions just got easier</Trans>
    </H4>
    <P color={ColorPreset.TextOnLight_02} textAlign={TextAlign.Center}>
      <Trans>
        These Subscription payments will be taken from the customer&apos;s
        existing mandate with you. They will be notified about the payment and
        we&apos;ll process it automatically with no hassle. It&apos;s that easy.
      </Trans>
    </P>
  </Box>
);
