import {
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";

import { getInputErrorStatus } from "src/utils/inputValidation";

export interface FormWithNameField {
  name: string;
}

interface NameFieldProps {
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
}

export const fieldName = "name";

export const NameField: React.FC<NameFieldProps> = ({
  label,
  errorMessage,
  placeholder,
  required = true,
  disabled = false,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<FormWithNameField>();

  return (
    <Field>
      <Label htmlFor={fieldName}>
        {label ?? <Trans id="plan-detail.name-field-label">Name</Trans>}
      </Label>
      <Input
        id={fieldName}
        status={getInputErrorStatus(!!errors[fieldName])}
        placeholder={placeholder}
        {...register(fieldName, { required })}
        disabled={disabled}
      />
      {errors[fieldName] ? (
        <Hint status={FormFieldStatus.Danger}>
          {errors[fieldName].message ||
            errors[fieldName].types?.message ||
            errorMessage || (
              <Trans id="plan-detail.name-field-error-message">
                Please enter a name
              </Trans>
            )}
        </Hint>
      ) : null}
    </Field>
  );
};
